

.my-sticky-dynamic
  /* height or max-height is important */
  height: 410px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: #fff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0
    background: #f5f4f7

.edit-btn
  font-size: 24px
  cursor: pointer

.sticky-actions-column

  thead tr:last-child th:last-child.actions-column
    /* bg color is important for th; just specify one */

  td:last-child.actions-column
    background-color: #f5f4f7
    box-shadow: -2px 0px 4px #e2e2e2

  th:last-child.actions-column,
  td:last-child.actions-column
    position: sticky
    right: 0
    // z-index: 1
    width: 150px


  .q-table th
    font-size:14px
