/* app global css */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
.width-100 {
  width: 100%;
}

.card-title {
  text-transform: uppercase;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 8px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 15px;
}

.condensed-font {
  font-family: "Roboto Condensed";
}

.q-page {
  /* background-color: #fff; */
  margin: 20px;
  /* padding: 20px; */
  min-height: 100vh;
}

.full-height {
  height: calc(100vh - 200px) !important;
  overflow: scroll;
}

.q-card {
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  border: 1px solid #e2e2e2;
}

.no-extra-height .q-item {
  min-height: 2px;
}

.filter-box .q-item {
  border: 1px solid #84c8c4;
  border-radius: 4px 4px 0 0;
}