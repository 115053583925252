
.blitz-field {
  grid-gap: 0px 16px;
}
.q-field--with-bottom {
  padding-bottom: 6px;
  padding-top: 3px;
}
.required-field .blitz-field__label:after {
  content: "*";
  color: red;
}
.filter-form {
  .q-field__control.no-wrap {
    flex-wrap: wrap;
  }
}
