
.clinic-card {
  border: 1px solid #e2e2e2;
  //   padding: 20px;
  background-color: aliceblue;
  border-radius: 10px;
}
.height-100 {
  height: 100%;
}
