
.q-field__control-container > .q-field__native.row {
  padding: 0 !important;
}
/* .q-field--borderless.q-field--dense .q-field__control {
  padding: 0 12px;
} */
/* .q-field__prefix,
.q-field__suffix,
.q-field__input {
  padding: 0 12px;
} */
// .q-field--borderless.q-field--dense .q-field__control {
//   .q-field__native {
//     padding: 0 12px;
//   }
//   .q-field__native.row {
//     padding: 0;
//   }
// }
// .q-validation-component {
//   padding: 0 12px;
// }
// .q-field--borderless .q-field__bottom,
// .q-field--standard .q-field__bottom,
// .q-field--borderless.q-field--dense .q-field__control,
// .q-field--standard.q-field--dense .q-field__control {
//   padding-left: 12px;
//   padding-right: 0;
// }
// .required-field {
//   .q-field__native {
//     padding: 0 12px;
//   }
// }
// .required-field {
//   .q-field--borderless.q-field--dense .q-field__control.row,
//   .q-field--standard.q-field--dense .q-field__control.row {
//     padding-left: 12px;
//   }
// }

// .q-field--borderless {
//   .q-field__control.row {
//     padding-left: inherit;
//   }
// }
.r-select {
  .q-field__control-container {
    padding-left: 12px;
  }
}
